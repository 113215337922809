<template>
  <div>
    <v-expansion-panels elevation="0">
      <v-expansion-panel elevation="0">
        <v-expansion-panel-header style="padding: 35px;">
          <h3 style="color:#42352E;">
            Búsqueda detallada
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.poliza"
                  label="No. de Póliza"
                  outlined
                  dense
                  :light="true"
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <input-periodo bgColor="white"></input-periodo>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="pt-5">
      <v-row>
        <v-col cols="6" md="4">
          <v-select
            v-model="form.status"
            label="Estatus"
            :items="statusCuotas"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="6" md="4">
          <v-select
            v-model="form.ramo"
            label="Ramo"
            :items="ramos"
            item-text="ramo"
            item-value="ramo"
            outlined
            dense
          ></v-select>
        </v-col>

        <v-col cols="12" md="2">
          <v-btn
            class="common-botton"
            dark
            block
            :disabled="!isFormValid || !minimoUnCampoLleno"
            :loading="loading"
            @click="updateSearch"
          >
            Filtrar
          </v-btn>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn
            dark
            block
            outlined
            color="#00a7e4"
            rounded
            @click="clearForm()"
          >
            Limpiar
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios.js";
import moment from "moment";
import InputPeriodo from "@/components/commonComponents/forms/InputPeriodo.vue";

export default {
  components: {
    InputPeriodo,
  },

  props: {
    currentFilters: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    statusDisponibles: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      requestConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      form: {
        poliza: "",
        status: "",
        ramo: "",
      },
      menuFecha: false,
      ramos: [],
      statusCuotas: this.statusDisponibles,

      isFormValid: true,
      localRules: {
        longDigit(number) {
          return (v) =>
            !v ||
            v.length == number ||
            "Debe proporcionar " + number + " digitos";
        },
      },
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },

  computed: {
    datesSelectedString() {
      if (this.form.rango_fechas.length > 1)
        return `${this.formatDate(
          this.form.rango_fechas[0]
        )} a ${this.formatDate(this.form.rango_fechas[1])}`;
      return "";
    },

    minimoUnCampoLleno() {
      return (
        Object.keys(this.form).filter(
          (e) => !!this.form[e] || this.form[e] === 0
        ).length >= 1
      );
    },
  },

  watch: {
    currentFilters(v) {
      !!v && this.setCurrent();
    },

    statusDisponibles(v) {
      if (!!v && !!v.length) this.statusCuotas = v;
    },
  },

  mounted() {
    this.getRamos();
    this.setCurrent();
  },

  methods: {
    getRamos() {
      mainAxios.get("/v1/ramo/list", this.requestConfig).then(({ data }) => {
        this.ramos = data;
      });
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    updateSearch() {
      if (!this.isFormValid || !this.minimoUnCampoLleno) return;
      let payload = {};
      Object.keys(this.form).forEach((e) => {
        if (!!this.form[e] || this.form[e] === 0) payload[e] = this.form[e];
      });

      this.$emit("searchUpdated", payload);
    },

    setCurrent() {
      this.clearForm();
      this.form = { ...this.form, ...this.currentFilters };
    },

    clearForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = "";
      });
    },
  },
};
</script>
<style scoped>
.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
  background-color: #f7f8fa;
}
.v-expansion-panel-header {
  background-color: #f7f8fa;
  border-radius: 20px !important;
}
</style>
