<template>
  <div class="main-border">
    <v-row class="pb-5">
      <v-col
        cols="12"
        md="6"
        :class="
          isCuotas ? 'text-center active-color' : 'text-center inactive-color'
        "
        @click="$router.push('/cuotas-hub')"
      >
        <span
          :class="
            isCuotas ? 'text-header-step-active' : 'text-header-step-no-active'
          "
        >
          Mis Cuotas</span
        >
      </v-col>
      <v-col
        cols="12"
        md="6"
        :class="
          isComisiones
            ? 'text-center active-color'
            : 'text-center inactive-color'
        "
        @click="$router.push('/comisiones-hub')"
      >
        <span
          :class="
            isComisiones
              ? 'text-header-step-active'
              : 'text-header-step-no-active'
          "
        >
          Mis Comisiones</span
        >
      </v-col>
    </v-row>
    <div>
      <div v-show="isCuotas">
        <cuotas-index></cuotas-index>
      </div>
      <div v-show="isComisiones">
        <cuotas-index :isComisiones="isComisiones"></cuotas-index>
      </div>
    </div>
  </div>
</template>
<script>
import CuotasIndex from "./CuotasIndex.vue";

export default {
  components: {
    CuotasIndex,
  },

  data() {
    return {};
  },

  computed: {
    isCuotas() {
      return this.$route.path == "/cuotas-hub";
    },

    isComisiones() {
      return this.$route.path == "/comisiones-hub";
    },
  },

  methods: {},
};
</script>
