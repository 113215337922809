<template>
  <div class="pt-5">
    <v-data-table
      :items="cuotas"
      :headers="headers"
      :loading="loading"
      no-data-text="Sin cuotas para mostrar"
      no-results-text="Sin resultados para mostrar"
      loading-text="Cargando..."
      :footer-props="{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Elementos por página',
      }"
    >
      <template v-slot:top>
        <v-row style="padding: 15px;">
          <v-col cols="12" md="8">
            <h2>Resultados de la búsqueda</h2>
          </v-col>
          <v-col cols="12" md="4" style="text-align: end;">
            <v-btn color="transparent" elevation="0" small>
              <span style="color:#0070C0;">Descargar</span>
              <v-icon small right class="mr-2" color="#0070C0"
                >mdi-file-pdf-box</v-icon
              >
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template #item.fechaPagoAcordada="{ item }">
        <span>{{
          item.fechaPagoAcordada
            ? formatFecha(item.fechaPagoAcordada)
            : "No disponible"
        }}</span>
      </template>

      <template #item.status="{ item }">
        <v-chip label>
          {{ item.status }}
        </v-chip>
      </template>

      <template #item.porcentajeComisionAgente="{ item }">
        <span>{{ item.porcentajeComisionAgente }}%</span>
      </template>

      <template #item.importeAPagarAgente="{ item }">
        <span>{{ currencyFormatt(item.importeAPagarAgente) }}</span>
      </template>

      <template #item.fechaPagoAplicado="{ item }">
        <span>{{
          item.fechaPagoAplicado
            ? formatFecha(item.fechaPagoAplicado)
            : "No disponible"
        }}</span>
      </template>

      <template #item.folioFacturaAPagar="{ item }">
        <span>{{ item.folioFacturaAPagar || "No disponible" }}</span>
      </template>

      <template #item.baseComision="{ item }">
        <span>{{
          item.baseComision
            ? currencyFormatt(item.baseComision)
            : "No disponible"
        }}</span>
      </template>

      <template #item.ver="{ item }">
        <v-btn icon x-small @click="verCuota(item.uuid)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>

      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        <div v-if="itemsLength">
          Mostrando {{ pageStart }} - {{ pageStop }} de
          {{ itemsLength }} solicitudes
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    cuotas: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isComisiones: Boolean,
  },

  data() {
    return {};
  },

  computed: {
    headers() {
      return this.isComisiones
        ? [
            {
              text: "Cuota",
              value: "id",
            },
            {
              text: "Póliza",
              value: "polizaId",
            },
            {
              text: "Orden",
              value: "orden",
            },
            {
              text: "Nombre Agente",
              value: "nombreAgente",
            },
            {
              text: "Estatus",
              value: "status",
            },
            {
              text: "% Comisión Agente",
              value: "porcentajeComisionAgente",
            },
            {
              text: "Importe a Pagar al Agente",
              value: "importeAPagarAgente",
            },
            {
              text: "Folio de la Factura a Pagar",
              value: "folioFacturaAPagar",
            },
            {
              text: "Ver",
              value: "ver",
            },
          ]
        : [
            {
              text: "Cuota",
              value: "id",
            },
            {
              text: "Póliza",
              value: "polizaId",
            },
            {
              text: "Estatus",
              value: "status",
            },
            {
              text: "Orden",
              value: "orden",
            },
            {
              text: "Valor a Pagar",
              value: "baseComision",
            },
            {
              text: "Fecha de Pago Acordada",
              value: "fechaPagoAcordada",
            },
            {
              text: "Fecha Pago de Cuota",
              value: "fechaPagoAplicado",
            },
            {
              text: "Ver",
              value: "ver",
            },
          ];
    },
  },

  methods: {
    verCuota(uuid) {
      this.$router.push(`/visualizacion-comisiones/${uuid}`);
    },

    formatFecha(fecha) {
      return fecha
        .split("-")
        .reverse()
        .join("/");
    },

    currencyFormatt(val) {
      const formatoMonedaMXN = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      });

      return formatoMonedaMXN.format(val);
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #f2fafc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}
</style>
