<template>
  <CommonExpansionPanel>
    <template v-slot:title-panel>
      Estadísticas
    </template>
    <v-expansion-panel-content>
      <div>
        <v-row v-if="hasDataToShow">
          <v-col cols="12" md="7">
            <div class="d-md-flex">
              <span
                class="text-subtitle-1 font-weight-bold mt-1 mr-2"
                style="color: #4D5358"
                >Filtrar por periodo</span
              >
              <InputPeriodo style="min-width: 50%;"></InputPeriodo>
            </div>
          </v-col>
          <v-col cols="12" md="6" xs="12" style="align-self: center;">
            <CardsInfoCuotas :cuotas="cuotas" />
          </v-col>
          <v-col cols="12" md="6">
            <cuotas-status-graphic
              v-if="showChart"
              :cuotas="cuotas"
            ></cuotas-status-graphic>
          </v-col>
        </v-row>
        <span v-else>Sin datos para mostrar</span>
      </div>
    </v-expansion-panel-content>
  </CommonExpansionPanel>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import CuotasStatusGraphic from "./CuotasStatus.graphic.vue";
import { mainAxios } from "@/mainAxios.js";
import CardsInfoCuotas from "./CardsInfoCuotas.vue";
import InputPeriodo from "@/components/commonComponents/forms/InputPeriodo.vue";

export default {
  components: {
    CommonExpansionPanel,
    CuotasStatusGraphic,
    CardsInfoCuotas,
    InputPeriodo,
  },

  data() {
    return {
      switch_data: 1,
      menuFecha: false,
      form: {
        rango_fechas: "",
      },
      cuotas: {
        "Pendientes de Pago": 0,
        Pagadas: 0,
        Vencidas: 0,
        Canceladas: 0,
      },
      showChart: false,
    };
  },

  watch: {
    "$route.params"() {
      this.comisiones = {};
      this.showChart = false;
      if (this.isComisiones) this.getTodasLasComisiones();
      else this.getTodasLasCuotas();
    },
  },

  props: {
    contactData: Object,
    correosDataFormat: Object,
    routesContactData: Object,
    isComisiones: Boolean,
  },

  mounted() {
    this.comisiones = {};
    this.showChart = false;
    if (this.isComisiones) this.getTodasLasComisiones();
    else this.getTodasLasCuotas();
  },

  methods: {
    changeComponent(component) {
      this.switch_data = component;
      this.$emit("getData");
    },
    async getTodasLasCuotas() {
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        const statusRoutes = {
          "Pendientes de Pago":
            "/v1/cuota/cuotasByAgenteWithStatusPendienteDePago",
          Pagadas: "/v1/cuota/cuotasByAgenteWithStatusPagadas",
          Vencidas: "/v1/cuota/cuotasByAgenteWithStatusVencida",
          Canceladas: "/v1/cuota/cuotasByAgenteWithStatusCanceladas",
        };

        for (const status of Object.keys(statusRoutes)) {
          const serverResponse = await mainAxios.get(
            `${statusRoutes[status]}/${localStorage.getItem("agenteUuid")}`,
            requestConfig
          );
          this.cuotas[status] = serverResponse.data
            ? serverResponse.data.length + 2
            : 0;
        }
        this.showChart = true;
        this.$emit("statusUpdated", Object.keys(this.cuotas));
      } catch (error) {
        console.error(error);
      }
    },

    async getTodasLasComisiones() {
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        const statusRoute = "/v1/cuota/comision/status/filtros";

        const serverResponse = await mainAxios.post(
          statusRoute,
          {},
          requestConfig
        );
        const comisiones = serverResponse?.data?.data?.comisiones;
        if (comisiones) {
          this.cuotas = comisiones;
        }
        this.$emit("statusUpdated", Object.keys(comisiones));
      } catch (error) {
        console.error(error);
      } finally {
        this.showChart = true;
      }
    },
  },
  computed: {
    datesSelectedString() {
      if (this.form.rango_fechas.length > 1)
        return `${this.formatDate(
          this.form.rango_fechas[0]
        )} a ${this.formatDate(this.form.rango_fechas[1])}`;
      return "";
    },

    minimoUnCampoLleno() {
      return (
        Object.keys(this.form).filter(
          (e) => !!this.form[e] || this.form[e] === 0
        ).length >= 1
      );
    },

    hasDataToShow() {
      return !!Object.entries(this.cuotas).find((e) => e[1] > 0);
    },
  },
};
</script>
