<template>
  <CommonExpansionPanel>
    <template v-slot:title-panel>
      Buscar
    </template>
    <v-expansion-panel-content>
      <filters-cuotas
        @searchUpdated="getSolicitudes"
        :statusDisponibles="statusDisponibles"
        :loading="loading"
      ></filters-cuotas>
      <hr />
      <table-cuotas
        :loading="loading"
        :cuotas="cuotas"
        :isComisiones="isComisiones"
      ></table-cuotas>
    </v-expansion-panel-content>
  </CommonExpansionPanel>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import FiltersCuotas from "./FiltersCuotas.vue";
import TableCuotas from "./TableCuotas.vue";
import { mainAxios } from "@/mainAxios.js";

export default {
  components: {
    CommonExpansionPanel,
    FiltersCuotas,
    TableCuotas,
  },

  data() {
    return {
      switch_data: 1,
      cuotas: [],
      loading: false,
    };
  },
  props: {
    contactData: Object,
    correosDataFormat: Object,
    routesContactData: Object,
    statusDisponibles: Array,
    isComisiones: Boolean,
  },
  methods: {
    getSolicitudes(body) {
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.loading = true;
      mainAxios
        .post(`/v1/cuota/list/filtros`, body, requestConfig)
        .then(({ data }) => {
          this.loading = false;
          if (data?.data?.cuotas) this.cuotas = data.data.cuotas;
          else this.cuotas = [];
        });
    },

    getTodasLasSolicitudes() {
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(`/v1/solicitud/info/status/1`, requestConfig)
        .then(({ data }) => {
          this.todasLasSolicitudes = data;
        });
    },
  },
};
</script>
