<template>
  <div>
    <v-row>
      <v-col cols="10" md="10">
        <span class="titulo-header">{{
          isComisiones ? "Mis Comisiones" : "Mis Cuotas"
        }}</span>
      </v-col>
    </v-row>
    <div style="padding-top: 30px;">
      <stats-cuotas
        :isComisiones="isComisiones"
        @statusUpdated="statusUpdated"
      ></stats-cuotas>
    </div>
    <div style="padding-top: 30px;">
      <filters-cuotas-index
        :statusDisponibles="statusDisponibles"
        :isComisiones="isComisiones"
      ></filters-cuotas-index>
    </div>
  </div>
</template>

<script>
import FiltersCuotasIndex from "./FiltersCuotasIndex.vue";
import StatsCuotas from "./StatsCuotas.vue";

export default {
  components: {
    FiltersCuotasIndex,
    StatsCuotas,
  },

  props: {
    isComisiones: {
      type: Boolean,
    },
  },

  data() {
    return {
      statusDisponibles: [],
    };
  },
  methods: {
    statusUpdated(status) {
      this.statusDisponibles = status;
    },
  },
};
</script>
